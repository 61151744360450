import "./App.css";
import Home from "./pages/home/Home";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/auth/Register";

function App() {
  return (
    <div className="App w-full">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;
